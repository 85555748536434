<template>
  <div
    class="portrait-wrap"
    v-loading="loading"
  >
    <h3 class="w-title-gradients">项目周边同业态竞争对比</h3>
    <div class="select-wrap">
      <div class="w-select-screen-border">
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="type"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
          style="width: 338px;"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="brand-wrap">
      <div class="item">
        <div class="charts-wrap">
          <div
            class="charts"
            v-for="index in (comProjectIds.length + 1)"
            :key="index"
          >
            <div
              v-show="showBar[index-1]"
              :id="'portraitBar' + index"
            ></div>
            <div
              v-show="!showBar[index-1]"
              class="no-data"
            >
              <div>
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      type: 1,
      typeList: [{ label: '项目周边半径1公里内各业态购物中心店分布', value: 1 }, { label: '项目周边半径1公里内各业态街铺店分布', value: 2 }],
      colors: ['#37ABFD', '#10DD71', '#5C61B6', '#00D7D5', '#83AF9B'],
      showBar: [],
      loading: false
    }
  },
  props: {
    projectId: {
      type: String
    },
    comProjectIds: {
      type: Array
    },
    decisionId: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const data = {
        projectId: this.projectId,
        comProjectIds: this.comProjectIds,
        type: this.type,
        radiusType: 1, // 1公里
        decisionId: this.decisionId
      }
      this.axios.post(api.getBrandNumVoList, data)
        .then((res) => {
          if (res.data.code === 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].list && res.data.data[i].list.length > 0) {
                this.$set(this.showBar, i, true)
                this.$nextTick(() => {
                  this.barChartsFunc('portraitBar' + (i + 1), res.data.data[i].list)
                })
              } else {
                this.$set(this.showBar, i, false)
              }
            }
            this.loading = false
          }
        })
    },
    changeType () {
      this.loading = true
      this.init()
    },
    barChartsFunc (dom, data) {
      const params = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const obj = {}
          obj.value = data[i].num
          obj.name = data[i].industry
          obj.percent = data[i].proportion
          params.push(obj)
        }
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        legend: {
          top: 220,
          orient: 'horizontal',
          x: 'center',
          y: 'bottom',
          show: true,
          textStyle: {
            color: '#808191',
            fontSize: 12
          },
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            top: 0,
            name: '',
            type: 'pie',
            radius: [55, 75],
            center: ['50%', 120],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              show: true,
              type: 'text',
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                formatter: [
                  '{d|{d}%}',
                  '{c|{c}}',
                  '{b|{b}}'
                ].join('\n'),
                rich: {
                  b: {
                    color: '#fff',
                    fontSize: 14
                  },
                  c: {
                    color: '#fff',
                    fontWeight: 400,
                    lineHeight: 28,
                    fontSize: 14
                  },
                  d: {
                    fontWeight: 400,
                    fontSize: 24
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
      this.$nextTick(() => {
        const piechart = document.getElementById(dom)
        const row = Number((data.length / 4).toFixed(0))
        piechart.style.height = 255 + row * 20 + 'px'
        myChart.resize()
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  border-radius 2px
  .el-input__inner
    box-sizing border-box
    width 338px
    height 34px
    border 1px solid #373A43
    color #fff
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.portrait-wrap
  // min-height 409px
  background-color #272930
  margin-top 14px
  padding-bottom 30px
  .select-wrap
    position relative
    display flex
    .w-select-screen-border
      margin-left auto
  .brand-wrap
    // margin-top 50px
    .item
      .charts-wrap
        display flex
        .charts
          flex 1
          .no-data
            display flex
            align-items center
            justify-content center
            width unset
            height 300px
            padding 0
        #portraitBar1, #portraitBar2, #portraitBar3
          width 430px
          height 300px
          margin auto
      .industry-colors
        display flex
        justify-content center
        .colors-item
          .name
            font-size 12px
            color #808191
            font-weight 400
            span
              display inline-block
              width 8px
              height 8px
              border-radius 50%
              vertical-align initial
              margin-left 22px
              margin-right 5px
          .value
            margin-left 30px
            font-size 14px
            color #fff
</style>
