<template>
  <div
    class="industry-wrap"
    v-loading="loading"
  >
    <h3 class="w-title-gradients">品牌对比</h3>
    <div class="select-wrap">
      <div class="w-select-screen-border">
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="lcOrYtType"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="brand-wrap">
      <div class="item">
        <div class="charts-wrap">
          <div
            class="charts"
            v-for="index in (comProjectIds.length + 1)"
            :key="index"
          >
            <div v-if="showBar[index-1]">
              <div :id="'bar' + index"></div>
            </div>
            <div
              v-if="!showBar[index-1]"
              class="no-data"
            >
              <div>
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <div class="industry-colors">
          <div
            class="colors-item"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
          </div>
        </div>
        <div class="index-table-wrap">
          <div
            class="index-table"
            v-for="item in brandCompartList"
            :key="item.operating"
          >
            <div class="table">
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.hotType"
                >人气更高</span>
                <div class="value">{{item.hot}}</div>
                <div class="label">平均人气指数</div>
              </div>
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.operatingType"
                >贡献更大</span>
                <div class="value">{{item.operating}}</div>
                <div class="label">平均租金贡献指数</div>
              </div>
              <div class="table-item">
                <span
                  class="fixed"
                  v-if="item.comprehensiveType"
                >实力更强</span>
                <div class="value">{{item.comprehensive}}</div>
                <div class="label">平均综合实力指数</div>
              </div>
            </div>
          </div>
        </div>
        <div class="wordCloud-wrap">
          <div
            v-for="(item, index) in brandCompartList"
            :key="index"
          >
            <div
              v-if="item.labelPortraitList.length>0"
              class="label"
            >
              <wordcloud
                :data="item.labelPortraitList"
                style="width: 436px;height: 236px; background: #1E1E23; border-radius: 2px"
                :rotate="{from: 0, to: 0, numOfOrientation: 0 }"
                :showTooltip="false"
                font="Arial"
                :fontSize="[14, 28]"
                :color="['#37ABFD','#10DD71','#00D7D5', '#F1D073']"
              ></wordcloud>
            </div>
            <div
              v-else
              class="no-data"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
        <div class="industry-list-wrap">
          <div
            class="industry-list scroll-y"
            v-for="(item, index) in brandCompartList"
            :key="index"
          >
            <div
              v-if="item.brandList.length > 0"
              class="list-wrap"
            >
              <div
                class="item"
                v-for="(list, list1) in item.brandList"
                :key="list1"
              >
                <div class="name">{{list.ytOrlcName}}</div>
                <div class="brand-name">
                  <span
                    v-for="name in list.brandList"
                    :key="name.brandId"
                    @click="toDetail(name)"
                  > {{name.brandName}} </span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="no-data"
            >
              <div>
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import wordcloud from 'vue-wordcloud'
export default {
  data () {
    return {
      lcOrYtType: 0,
      brandCompartList: [],
      typeList: [{ label: '按楼层', value: 0 }, { label: '按业态', value: 1 }],
      colors: ['#37ABFD', '#10DD71', '#5C61B6', '#00D7D5', '#83AF9B'],
      showBar: [],
      loading: false
    }
  },
  components: {
    wordcloud
  },
  props: {
    projectId: {
      type: String
    },
    comProjectIds: {
      type: Array
    },
    decisionId: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const data = {
        projectId: this.projectId,
        comProjectIds: this.comProjectIds,
        decisionId: this.decisionId,
        lcOrYtType: this.lcOrYtType
      }
      this.axios.post(api.lcorytCompart, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.brandCompartList = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].areaAndRatioList && res.data.data[i].areaAndRatioList.length > 0) {
                this.$set(this.showBar, i, true)
                this.$nextTick(() => {
                  this.barChartsFunc('bar' + (i + 1), res.data.data[i].areaAndRatioList)
                })
              } else {
                this.$set(this.showBar, i, false)
              }
            }
            this.loading = false
          }
        })
    },
    changeType () {
      this.loading = true
      this.init()
    },
    barChartsFunc (dom, data) {
      const params = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const obj = {}
          obj.value = data[i].area
          obj.percent = data[i].ratio
          if (this.lcOrYtType === 0) {
            obj.name = data[i].floor
          } else {
            obj.name = data[i].industry
          }
          params.push(obj)
        }
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        legend: {
          top: 220,
          orient: 'horizontal',
          x: 'center',
          y: 'bottom',
          show: true,
          textStyle: {
            color: '#808191',
            fontSize: 12
          },
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            top: 0,
            type: 'pie',
            radius: [55, 75],
            center: ['50%', 120],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              show: true,
              type: 'text',
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                formatter: [
                  '{d|{d}%}',
                  '{c|{c}㎡}',
                  '{b|{b}}'
                ].join('\n'),
                rich: {
                  b: {
                    color: '#fff',
                    fontSize: 14
                  },
                  c: {
                    color: '#fff',
                    lineHeight: 28,
                    fontWeight: 400,
                    fontSize: 14
                  },
                  d: {
                    fontWeight: 400,
                    fontSize: 24
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
      const piechart = document.getElementById(dom)
      this.$nextTick(() => {
        if (this.lcOrYtType === 1) { // 业态：一行放5个图例，计算行数，来得出高度
          const row = Number((data.length / 5).toFixed(0))
          console.log('row1', row)
          piechart.style.height = 250 + row * 20 + 'px'
        } else { // 楼层：一行放6个图例，计算行数，来得出高度
          const row = Number((data.length / 6).toFixed(0))
          piechart.style.height = 220 + row * 20 + 'px'
          console.log('row2', row)
        }
        console.log('piechart.style.height', piechart.style.height)
        myChart.resize()
      })
    },
    toDetail (item) {
      if (item.state !== -6) {
        const brandDetailsHref = this.$router.resolve({
          path: '/brandDetail',
          query: {
            id: item.brandId,
            source: 1,
            gbid: item.gbId,
            questionState: 0
          }
        })
        window.open('/' + brandDetailsHref.href, '_blank')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  .el-input__inner
    box-sizing border-box
    width 110px
    height 34px
    border 1px solid #373A43
    color #fff
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.industry-wrap
  background-color #272930
  margin-top 14px
  padding-bottom 60px
  .select-wrap
    position relative
    display flex
    .w-select-screen-border
      margin-left auto
    /deep/ .el-select
      border-radius 2px !important
  .brand-wrap
    // display flex
    .item
      // flex 1
      .charts-wrap
        display flex
        .charts
          flex 1
          .no-data
            display flex
            align-items center
            justify-content center
            height 250px
            width unset
        #bar1, #bar2, #bar3
          width 430px
          height 250px
          margin auto
      .industry-colors
        display flex
        justify-content center
        .colors-item
          flex 1
          text-align center
          display flex
          .type-wrap
            .name
              font-size 12px
              color #808191
              font-weight 400
              span
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                vertical-align initial
                margin-left 22px
                margin-right 5px
          .value
            margin-left 30px
            font-size 14px
            color #fff
      .index-table-wrap
        display flex
        .index-table
          flex 1
          margin-top 20px
          .table
            max-width 430px
            border 1px solid #373A43
            height 116px
            display flex
            justify-content center
            align-items center
            margin auto
            .table-item
              height 116px
              width 33%
              border-right 1px solid #373A43
              text-align center
              position relative
              &:last-child
                border none
              .value
                margin-top 27px
                font-size 24px
                font-weight bold
                color #fff
              .label
                font-size 14px
                font-weight 400
                color #808191
              .fixed
                position absolute
                right 0%
                top 0px
                width 64px
                height 31px
                box-shadow 0px 4px 16px rgba(0, 0, 0, 0.6)
                background-color rgba(0, 0, 0, 0.85)
                color #FF754C
                font-size 12px
                line-height 31px
                text-align center
      .industry-list-wrap
        display flex
        margin auto
        margin-top 17px
        // max-width 1600px
        padding 15px 0
        .industry-list
          flex 1
          height 370px
          overflow-y auto
          max-width 430px
          margin auto
          .list-wrap
            padding 0 20px
            .item
              max-width 430px
              margin auto
              margin-top 44px
              .name
                text-align left
                font-size 16px
                font-weight bold
                color #fff
              .brand-name
                text-align left
                span
                  color rgba(128, 129, 145, 1)
                  font-size 14px
                  margin-right 5px
                  line-height 22px
                  cursor pointer
                  word-wrap break-word
                  &:hover
                    color #fff
                    border-bottom 1px solid #fff
                  &.active
                    color #ff0000 !important
        .no-data
          display flex
          align-items center
          justify-content center
          width unset
          height 370px
      .wordCloud-wrap
        margin-top 32px
        display flex
        align-items center
        justify-content space-around
        .label
          width 436px
          height 236px
          background #1E1E23
          border-radius 2px
      .no-data
        width 429px
        padding 0
@media screen and (max-width 1650px)
  .industry-wrap .brand-wrap .item .index-table .table
    margin auto !important
  .industry-wrap .brand-wrap .item .industry-list-wrap .industry-list .list-wrap
    padding 0 15px !important
</style>
