<template>
  <div
    class="periphery-wrap"
    v-loading="loading"
  >
    <h3 class="w-title-gradients">项目周边人群画像对比
      <!-- <el-tooltip
        class="item"
        popper-class="planTooltip"
        effect="dark"
        placement="bottom"
      >
        <span>
          <i class="icon-help-tips"></i>
        </span>
        <div slot="content">显示项目周边半径1-3公里范围内的可监测人群信息</div>
      </el-tooltip> -->
    </h3>
    <div class="select-wrap">
      <div class="w-select-screen-border">
        <div class="radio-wrap">
          <el-radio
            v-model="jobOrPerType"
            label="1"
            @change="chageRadio"
          >工作人口</el-radio>
          <el-radio
            v-model="jobOrPerType"
            label="2"
            @change="chageRadio"
          >常住人口</el-radio>
        </div>
        <el-select
          popper-class="w-block-select-down w-block-select-down-border"
          v-model="radiusType"
          @change="changeType"
          placeholder="请选择"
          size='medium'
          class="select-extra"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="population-wrap">
      <div class="item-wrap">
        <div
          class="item"
          v-for="item in brandCompartList"
          :key="item.projectId"
        >
          <div class="population-number">
            <div class="value">{{item.population}}人</div>
            <div class="label">周边{{jobOrPerType === '1' ? '工作' : '常住'}}人口</div>
          </div>
        </div>
      </div>
      <div class="sex-charts">
        <div class="brand-tips"> <span></span> 性别分布</div>
        <div
          v-for="index in (comProjectIds.length + 1)"
          :key="index"
          class="chart-item"
        >
          <div
            v-show="showBar2[index-1]"
            class="charts-bar2"
            :id="'sexBar' + (index)"
          ></div>
          <div
            v-show="!showBar2[index-1]"
            class="no-data"
          >
            <div>
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sex-charts">
        <div class="brand-tips"> <span></span> 年龄分布</div>
        <div
          v-for="index1 in (comProjectIds.length + 1)"
          :key="index1"
          class="chart-item"
        >
          <div
            v-show="showBar1[index1-1]"
            class="charts-bar1"
            :id="'ageBar' + (index1)"
          ></div>
          <div
            v-show="!showBar1[index1-1]"
            class="no-data"
          >
            <div>
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="progress-wrap">
        <div class="brand-tips"> <span></span> 行业分布</div>
        <div
          class="progress-item"
          v-for="(item, index2) in brandCompartList"
          :key="index2"
        >
          <div class="scroll-y">
            <div v-if="item.business && item.business.length > 0">
              <div
                class="content-item"
                v-for="(item1, idx) in item.business"
                :key="idx"
              >
                <div class="info">
                  <div class="text">{{item1.lable}}</div>
                  <div class="value">{{item1.percent}}%</div>
                </div>
                <div class="progress">
                  <div
                    :style="{'width': item1.percent + '%'}"
                    class="value"
                  ></div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="no-data"
            >
              <div>
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      brandCompartList: [],
      jobOrPerType: '1',
      typeList: [{ label: '周边半径1公里', value: 1 }, { label: '周边半径2公里', value: 2 }, { label: '周边半径3公里', value: 3 }],
      radiusType: 1,
      colors: ['#37ABFD', '#10DD71', '#5C61B6', '#00D7D5', '#83AF9B'],
      showBar1: [],
      showBar2: [],
      loading: false
    }
  },
  props: {
    projectId: {
      type: String
    },
    comProjectIds: {
      type: Array
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const data = {
        projectId: this.projectId,
        comProjectIds: this.comProjectIds,
        radiusType: this.radiusType,
        jobOrPerType: this.jobOrPerType
      }
      this.axios.post(api.portrait, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.brandCompartList = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].sex && res.data.data[i].sex.labels) {
                this.$set(this.showBar1, i, true)
                this.$nextTick(() => {
                  this.barChartsFunc('sexBar' + (i + 1), res.data.data[i].sex)
                })
              } else {
                this.$set(this.showBar1, i, false)
              }
              if (res.data.data[i].age && res.data.data[i].age.labels) {
                this.$set(this.showBar2, i, true)
                this.$nextTick(() => {
                  this.barChartsFunc2('ageBar' + (i + 1), res.data.data[i].age)
                })
              } else {
                this.$set(this.showBar2, i, false)
              }
            }
            this.loading = false
          }
        })
    },
    chageRadio () {
      this.loading = true
      this.init()
    },
    changeType () {
      this.loading = true
      this.init()
    },
    barChartsFunc (dom, data) {
      // console.log(dom, data)
      const params = []
      if (data.labels) {
        for (let i = 0; i < data.labels.length; i++) {
          const obj = {}
          obj.value = data.values[i]
          obj.name = data.labels[i]
          params.push(obj)
        }
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        legend: {
          top: 200,
          left: 'center',
          show: true,
          textStyle: {
            color: '#808191'
          },
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle'
        },
        color: this.colors,
        series: [
          {
            top: 0,
            name: '',
            type: 'pie',
            radius: [50, 70],
            center: ['50%', 110],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              show: true,
              type: 'text',
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                formatter: [
                  '{c|{c}%}',
                  '{b|{b}}'
                ].join('\n'),
                rich: {
                  b: {
                    color: '#fff',
                    fontSize: 14
                  },
                  c: {
                    color: '#10DD71',
                    fontWeight: 400,
                    lineHeight: 28,
                    fontSize: 24
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          myChart.resize()
        })
      })
    },
    barChartsFunc2 (dom, data) {
      const clentWidth = document.body.clientWidth
      if (clentWidth < 1740) {
        const piechart = document.getElementById(dom)
        piechart.style.width = 380 + 'px'
        piechart.style.height = 350 + 'px'
      }
      const params = []
      if (data.labels) {
        for (let i = 0; i < data.labels.length; i++) {
          const obj = {}
          obj.value = data.values[i]
          obj.name = data.labels[i]
          params.push(obj)
        }
      }
      const myChart = echarts.init(document.getElementById(dom))
      const option = {
        legend: {
          top: 180,
          x: 'center',
          y: 'bottom',
          show: true,
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle',
          formatter: function (name) {
            let target = ''
            for (var i = 0, l = params.length; i < l; i++) {
              if (params[i].name === name) {
                target = params[i].value
              }
            }
            var arr = [
              '{b|' + name + '岁}',
              '{a|' + target + '%}'
            ]
            return arr.join('\n')
          },
          textStyle: {
            rich: {
              a: {
                color: '#fff',
                fontSize: 14,
                align: 'center'
              },
              b: {
                color: '#808191',
                fontSize: 12,
                padding: [0, 0, 15, 0],
                lineHeight: 35
              }
            }
          }
        },
        color: this.colors,
        series: [
          {
            top: 0,
            name: '',
            type: 'pie',
            radius: [50, 70],
            center: ['50%', 110],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              show: true,
              type: 'text',
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                formatter: [
                  '{c|{c}%}',
                  '{b|{b}岁}'
                ].join('\n'),
                rich: {
                  b: {
                    color: '#fff',
                    fontSize: 14
                  },
                  c: {
                    color: '#10DD71',
                    fontWeight: 400,
                    lineHeight: 28,
                    fontSize: 24
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: params
          }
        ]
      }
      myChart.setOption(option)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          const clentWidth = document.body.clientWidth
          const piechart = document.getElementById(dom)
          if (clentWidth <= 1740) {
            piechart.style.width = 380 + 'px'
            piechart.style.height = 350 + 'px'
          } else {
            piechart.style.width = 550 + 'px'
            piechart.style.height = 272 + 'px'
          }
          myChart.resize()
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  margin-right 18px
  background #373A43
  border-radius 2px
  .el-input__inner
    box-sizing border-box
    width 160px
    height 34px
    border 1px solid #373A43
    color #fff
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.periphery-wrap
  background-color #272930
  margin-top 14px
  padding-bottom 40px
  .radio-wrap
    display inline-block
    margin-left auto
    margin-right 50px
  .select-wrap
    position relative
    display flex
    .w-select-screen-border
      margin-left auto
  .population-wrap
    margin-top 50px
    .item-wrap
      display flex
      padding-bottom 20px
      .item
        flex 1
        text-align center
        .value
          margin-bottom 10px
          font-size 24px
          font-weight 400
          color #fff
        .label
          color #808191
          font-size 14px
    .sex-charts
      display flex
      position relative
      border 1px solid #373A43
      margin 40px 16px
      .chart-item
        flex 1
        display flex
        justify-content center
        align-items center
        .charts-bar1
          width 550px
          height 272px
          margin auto
        .charts-bar2
          width 380px
          height 245px
          margin auto
        .colors
          display flex
          justify-content center
          .colors-item
            .name
              font-size 12px
              color #808191
              font-weight 400
              span
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                vertical-align initial
                margin-left 22px
                margin-right 5px
            .value
              margin-left 30px
              font-size 14px
              color #fff
      .no-data
        padding 0
    .progress-wrap
      display flex
      position relative
      border 1px solid #373A43
      margin 40px 16px
      padding-bottom 40px
      justify-content center
      padding-top 40px
      .progress-item
        flex 1
        text-align center
        margin-top 50px
        .scroll-y
          width 400px
          height 470px
          margin auto
          overflow-y auto
          .content-item
            padding 0 40px
            margin-bottom 30px
            .info
              display flex
              .text
                margin-right auto
                font-size 14px
                color #B2B3BD
              .value
                margin-left auto
                color #fff
                font-size 16px
            .progress
              position relative
              height 10px
              width 100%
              background-color #373A43
              border-radius 5px
              margin-top 7px
              .value
                position absolute
                left 0
                top 0
                width 70%
                height 10px
                background-color #3E8CFF
                border-radius 5px
.brand-tips
  position absolute
  left 20px
  top 40px
  font-size 16px
  font-weight bold
  color #FFF
  span
    position absolute
    left -6px
    top 4px
    display inline-block
    width 2px
    height 16px
    background-color #F89407
</style>
