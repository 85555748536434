<template>
  <div class="comparison-content-wrap">
    <div class="w-crumbs-href">
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/work' }"
      >招商规划管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <span>{{$route.meta.title}}</span>
    </div>
    <div class="comparison-list">
      <div
        class="item"
        v-for="(item, index) in compContProjectList"
        :key="index"
      >
        <div class="project-img">
          <img
            :src="item.projectLogo ? item.projectLogo + '?w=222&h=166' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
            alt=""
          >
        </div>
        <div class="project-name">{{item.projectName}}</div>
      </div>
    </div>
    <div class="project-info">
      <h3 class="w-title-gradients">项目信息</h3>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in projectInfoList"
          :key="index"
        >
          <div class="info">
            <div class="value">{{item.projectTypeName}}</div>
            <div class="label">项目类型</div>
          </div>
          <div class="info">
            <div class="value">{{item.projectGradeName ? item.projectGradeName : '-'}}
              <span
                class="fixed"
                v-if="item.projectGradeType"
              >{{item.projectGradeType === 1 ? '档次更高' : '档次较低'}}</span>
            </div>
            <div class="label">项目档次</div>
          </div>
          <div class="info">
            <div class="value">开业{{item.kaiyeDuration ? item.kaiyeDuration + '年' : '-'}}
              <span
                class="fixed"
                v-if="item.kaiyeDurationType"
              >{{item.kaiyeDurationType === 1 ? '开业更久' : '开业较短'}}</span>
            </div>
            <div class="label">开业时间</div>
          </div>
          <div class="info">
            <div
              class="value"
              v-if="item.minFloor && item.maxFloor"
            >{{item.minFloor}}到{{item.maxFloor}}层</div>
            <div
              class="value"
              v-else
            >-</div>
            <div class="label">商业楼层</div>
          </div>
          <div class="info">
            <div class="value">{{item.shangyeArea}}㎡
              <span
                class="fixed"
                v-if="item.shangyeAreaType"
              >{{item.shangyeAreaType === 1 ? '面积更大' : '面积较小'}}</span>
            </div>
            <div class="label">商业面积</div>
          </div>
          <div class="info">
            <div class="value">{{item.belongDistrict ? item.belongDistrict : '-'}}</div>
            <div class="label">所属商圈</div>
          </div>
          <div class="info">
            <div class="value">{{item.romoveRepeatFlow ? item.romoveRepeatFlow + '人' : '-'}}
              <span
                class="fixed"
                v-if="item.romoveRepeatFlowType"
              >{{item.romoveRepeatFlowType === 1 ? '客流更旺' : '客流较差'}}</span>
            </div>
            <div class="label">平日去重客流</div>
          </div>
          <div class="info">
            <div class="value">{{item.romoveRepeatFlowByHolidays ? item.romoveRepeatFlowByHolidays + '人' : '-'}}
              <span
                class="fixed"
                v-if="item.romoveRepeatFlowByHolidaysType"
              >{{item.romoveRepeatFlowByHolidaysType === 1 ? '客流更旺' : '客流较差'}}</span>
            </div>
            <div class="label">节假日去重客流</div>
          </div>
        </div>
      </div>
    </div>
    <div class="insustry-wrap">
      <industry-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :projectId="projectId"
        :decisionId="decisionId"
        :comProjectIds="comProjectIds"
      ></industry-comparison>
    </div>
    <div class="portrait-wrap">
      <portrait-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :projectId="projectId"
        :decisionId="decisionId"
        :comProjectIds="comProjectIds"
      ></portrait-comparison>
    </div>
    <div class="periphery-wrap">
      <periphery-comparison
        v-if="comProjectIds && comProjectIds.length > 0"
        :projectId="projectId"
        :comProjectIds="comProjectIds"
      ></periphery-comparison>
    </div>
    <comparison-tab
      v-if="tabProjectList && tabProjectList.length > 0"
      @reloadCompartClick="reloadCompartClick"
      :tabProjectList="tabProjectList"
      :projectId="projectId"
      :decisionId="decisionId"
    ></comparison-tab>
  </div>
</template>

<script>
import api from '@/api'
import comparisonTab from '@/views/workBench/competitionComparison/components/comparisonTab.vue'
import industryComparison from '@/views/workBench/competitionComparison/components/industryComparison.vue'
import portraitComparison from '@/views/workBench/competitionComparison/components/portraitComparison.vue'
import peripheryComparison from '@/views/workBench/competitionComparison/components/peripheryComparison.vue'
export default {
  data () {
    return {
      compContProjectList: [],
      tabProjectList: [],
      comProjectIds: [],
      projectInfoList: []
    }
  },
  components: {
    comparisonTab,
    industryComparison,
    portraitComparison,
    peripheryComparison
  },
  props: {
    projectId: {
      type: String
    },
    decisionId: {
      type: String
    }
  },
  mounted () {
    // console.log('对比详情projectId', this.projectId)
    // console.log('对比详情decisionId', this.decisionId)
    if (this.$route.name === 'ComparisonContent') {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
      this.init()
      this.getProjectList()
    }
  },
  methods: {
    init () {
      const data = {
        projectId: this.projectId
      }
      this.axios.post(api.afterProjectInfoList, data)
        .then((res) => {
          this.compContProjectList = res.data.data
          for (const item of this.compContProjectList) {
            if (item.projectId !== Number(this.projectId) && item.projectId !== 0) {
              this.comProjectIds.push(item.projectId)
            }
          }
          this.getSeatingFunc()
        })
    },
    getProjectList () {
      this.reloadCompartClick()
    },
    reloadCompartClick () {
      const data = {
        projectId: this.projectId
      }
      this.axios.post(api.getTabComprojectListAtCompetition, data)
        .then((res) => {
          this.tabProjectList = res.data.data
        })
    },
    getSeatingFunc () {
      const projectIds = JSON.parse(JSON.stringify(this.comProjectIds))
      projectIds.unshift(this.projectId)
      const params = {
        projectIds: projectIds
      }
      this.axios.post(api.projectInfoList, params)
        .then((res) => {
          this.projectInfoList = res.data.data
        })
    },
    isCompartClick (item) {
      const data = {
        projectId: this.projectId,
        comProjectId: item.projectId
      }
      if (item.isCompart === 0) {
        this.axios.post(api.addCompartAtCompetition, data)
          .then((res) => {
            if (res.data.code === 0) {
              this.init()
            }
          })
      } else {
        this.axios.post(api.deleteCompartAtCompetition, data)
          .then((res) => {
            this.init()
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.comparison-content-wrap
  padding-bottom 80px
  margin 0 24px
  .w-crumbs-href
    margin 12px 24px
    .link-item
      color #808191
    i
      color #808191
    span
      color #fff
  .comparison-list
    padding-top 24px
    height 202px
    background-color #272930
    border-radius 2px
    display flex
    .item
      flex 1
      text-align center
      .project-img
        img
          width 160px
          height 120px
          object-fit cover
          border-radius 2px
      .project-name
        font-weight 400
        font-size 16px
        color #fff
        margin-top 12px
.project-info
  margin-top 14px
  padding-bottom 20px
  background-color #272930
  .item-wrap
    display flex
    .item
      flex 1
      text-align center
      margin-top 30px
      .info
        font-weight 400
        .value
          display inline-block
          font-size 24px
          color #fff
          position relative
          .fixed
            position absolute
            right -70px
            top -15px
            width 64px
            height 31px
            box-shadow 0px 4px 16px rgba(0, 0, 0, 0.6)
            background-color rgba(0, 0, 0, 0.85)
            color #FF754C
            font-size 12px
            line-height 31px
            text-align center
        .label
          margin-top 8px
          font-size 14px
          color #808191
          margin-bottom 40px
</style>
