<template>
  <div>
    <div class="comparison-tab-wrap-show">
      <div
        class="tab-left"
        @click="hideTab()"
      >
        <!-- <span class="icon-font icon-page-enter"></span> -->
        对比栏
        <span class="icon-font icon-page-return"></span>
      </div>
    </div>
    <transition name="slide-fade">
      <div
        class="comparison-tab-wrap"
        ref="comparisonBox"
      >
        <div
          class="tab-left"
          @click="hideTab()"
        >
          <span
            class="icon-font icon-page-enter"
            v-if="isHideTab"
          ></span>
          对比栏
          <span
            class="icon-font icon-page-return"
            v-if="!isHideTab"
          ></span>
        </div>
        <div class="tab-center">
          <div class="project-list">
            <div
              class="item"
              v-for="(item, index) in tabProjectList"
              :key="index"
              @mouseenter="showClose(index)"
              @mouseleave="leaveClose()"
            >
              <div class="project-info">
                <div class="info-wrap">
                  <img
                    :src="item.projectLogo ? item.projectLogo + '?w=159&h=119' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                    alt=""
                  >
                </div>

                <div class="info-detail">
                  <div
                    v-if="index === 0"
                    class="name"
                  >{{item.projectName}}</div>
                  <div
                    v-if="index !== 0"
                    class="search-input flexCenter"
                  >
                    <div
                      class="w-input-item w-input-sty isInput"
                      style="width: 210px"
                    >
                      <i class="el-icon-search"></i>
                      <input
                        class="ellipsis"
                        type="text"
                        v-model="item.projectName"
                        :disabled="true"
                      >
                    </div>
                    <div class="iconDel">
                      <i
                        class="icon-del-project"
                        @click="deleteCompProject(item, index)"
                      ></i>
                    </div>
                  </div>
                  <div class="address">
                    <span>{{item.provinceName}}/{{item.cityName}} </span>
                    <span v-if="item.shangquanType && item.projectGrade">{{item.shangquanType}}/{{item.projectGrade}}</span>
                    <span v-else-if="item.shangquanType">{{item.shangquanType}}</span>
                    <span v-else>{{item.projectGrade}}</span>
                  </div>
                  <div class="status">
                    <span>已开业{{item.kaiyeshichang}}年/</span><span>{{item.shangyeMianji}}㎡</span>
                  </div>
                  <!-- <div class="status2">
                    <span>同品牌店铺已留存约 <i>{{item.openDuration}}</i></span>
                  </div> -->
                </div>
              </div>
            </div>
            <div
              class="item"
              v-if="tabProjectList && tabProjectList.length < 2"
            >
              <div class="project-info">
                <div class="blank-img"></div>
                <div class="search-input">
                  <div
                    class="w-input-item w-input-sty"
                    style="width: 210px"
                  >
                    <i class="el-icon-search"></i>
                    <el-autocomplete
                      v-model="projectName1"
                      hide-loading
                      :fetch-suggestions="querySearchAsync1"
                      placeholder="输入想要对比的项目"
                      @select="handleSelect1"
                    ></el-autocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="item"
              v-if="tabProjectList && tabProjectList.length < 3"
            >
              <div class="project-info">
                <div class="blank-img"></div>
                <div class="search-input">
                  <div
                    class="w-input-item w-input-sty"
                    style="width: 210px"
                  >
                    <i class="el-icon-search"></i>
                    <el-autocomplete
                      v-model="projectName2"
                      hide-loading
                      :fetch-suggestions="querySearchAsync2"
                      placeholder="输入想要对比的项目"
                      @select="handleSelect2"
                    ></el-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-right">
          <span
            v-if="$route.name !== 'selectProject'"
            class="back-btn"
            @click="toComparisonContent"
          >返回项目推荐 <i class="icon-font icon-page-enter"></i> </span>
          <div
            class="btns"
            :class="[tabProjectList.length > 1 ? 'btn-yes' : '']"
            @click="comparisonBtn()"
          >对比</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      isHideTab: true,
      closeIdx: null,
      projectName1: '',
      projectName2: '',
      comProjectIds: [],
      tabProjectList: []
    }
  },
  props: {
    projectId: {
      type: String
    },
    decisionId: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    hideTab () {
      this.isHideTab = !this.isHideTab
      if (this.isHideTab) {
        this.$refs.comparisonBox.setAttribute('style', '')
      } else {
        this.$refs.comparisonBox.setAttribute('style', 'left: calc(100% - 52px) !important')
      }
    },
    init () {
      const data = {
        projectId: this.projectId
      }
      this.axios.post(api.getTabComprojectListAtCompetition, data)
        .then((res) => {
          this.tabProjectList = res.data.data
        })
    },
    querySearchAsync1 (queryString, cb) {
      if (queryString && queryString.length >= 2) {
        const params = {
          projectName: queryString,
          topNum: 7,
          wuyeLx: '购物中心,独立百货'
        }
        this.axios.post(api.getSearchProNameList, params)
          .then((res) => {
            res.data.data = res.data.data.map((item) => {
              item.value = item.projectName
              item.id = item.projectId
              return item
            })
            cb(res.data.data)
          })
      } else {
        const array = []
        cb(array)
      }
    },
    handleSelect1 (item) {
      this.projectName1 = ''
      this.projectName2 = ''
      const params = {
        comProjectId: item.projectId,
        projectId: this.projectId
      }
      this.axios.post(api.searchAtCompetition, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$emit('reloadCompartClick', item)
            this.init()
            // this.addCompartClick(item)
          }
        })
    },
    querySearchAsync2 (queryString, cb) {
      if (queryString && queryString.length >= 2) {
        const params = {
          projectName: queryString,
          topNum: 7,
          wuyeLx: '购物中心,独立百货'
        }
        this.axios.post(api.getSearchProNameList, params)
          .then((res) => {
            res.data.data = res.data.data.map((item) => {
              item.value = item.projectName
              item.id = item.projectId
              return item
            })
            cb(res.data.data)
          })
      } else {
        const array = []
        cb(array)
      }
    },
    handleSelect2 (item) {
      this.projectName1 = ''
      this.projectName2 = ''
      const params = {
        comProjectId: item.projectId,
        projectId: this.projectId
      }
      this.axios.post(api.searchAtCompetition, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$emit('reloadCompartClick', item)
            this.init()
            // this.addCompartClick(item)
          }
        })
    },
    toComparisonContent () {
      this.$router.push({
        path: '/competitionComparison',
        query: Object.assign({ projectId: this.projectId }, { decisionId: this.decisionId })
      })
    },
    comparisonBtn () {
      localStorage.setItem('isComparison', 'true')
      this.comProjectIds = []
      for (const item of this.tabProjectList) {
        // if (item.projectId !== this.projectId) { // 原来的
        if (item.projectId !== Number(this.projectId) && item.projectId !== 0) {
          this.comProjectIds.push(item.projectId)
        }
      }
      this.comProjectIds = [...new Set(this.comProjectIds)]
      if (this.comProjectIds.length === 0) {
        return false
      }
      // console.log('添加对比', this.comProjectIds)
      const params = {
        comProjectIds: this.comProjectIds,
        projectId: this.projectId
      }
      this.axios.post(api.addRecentProjectAtCompetition, params)
        .then((res) => {
          if (res.data.code === 0) {
            if (this.$route.name !== 'ComparisonContent') {
              this.$router.push({
                path: '/competitionComparison/comparisonContent',
                query: Object.assign({ projectId: this.projectId }, { decisionId: this.decisionId }, { _: +new Date() })
              })
            } else {
              window.location.reload()
            }
          }
        })
    },
    deleteCompProject (item) {
      // for (let i = 0; i < this.tabProjectListCopy.length; i++) {
      //   if (this.tabProjectListCopy[i].projectId === item.projectId) {
      //     this.tabProjectListCopy.splice(i, 1)
      //   }
      // }
      // console.log(this.tabProjectListCopy)
      this.projectName1 = ''
      this.projectName2 = ''
      const data = {
        projectId: this.projectId,
        comProjectId: item.projectId
      }
      this.axios.post(api.deleteCompartAtCompetition, data)
        .then((res) => {
          this.init()
          this.$emit('reloadCompartClick', item)
        })
    },
    showClose (index) {
      this.closeIdx = index
    },
    leaveClose () {
      this.closeIdx = null
    },
    addCompartClick (item) {
      const data = {
        projectId: this.projectId,
        comProjectId: item.projectId
      }
      this.axios.post(api.addCompartAtCompetition, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.init()
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.comparison-tab-wrap
  position fixed
  left 24px
  bottom 0
  width calc(100% - 24px)
  height 139px
  box-shadow 0px -4px 20px rgba(0, 0, 0, 0.8)
  background-color #272930
  z-index 111
  display flex
  border-radius 2px 0 0 2px
  transition left 0.3s ease-in-out
  .tab-left
    width 52px
    background-color #373A43
    writing-mode vertical-rl
    -webkit-writing-mode vertical-rl
    text-align center
    line-height 52px
    color #fff
    font-weight 400
    font-size 20px
    letter-spacing 10px
    text-indent 5px
    border-radius 2px 0 0 2px
    cursor pointer
    position relative
    display flex
    align-items center
    justify-content center
    .icon-page-enter:before
      width 12px
      height 22px
      position absolute
      top 40.1%
      right -18px
      color #fff
    .icon-page-return:before
      width 12px
      height 22px
      position absolute
      top 40.4%
      left 20px
      color #fff
    &:hover
      background-color #F89407
    &:focus
      background-color #DB8307
  .tab-right
    width 160px
    background-color #373A43
    position relative
    .back-btn
      width 133px
      height 36px
      line-height 36px
      text-align center
      position absolute
      left 20px
      top 10px
      cursor pointer
      font-size 14px
      color #fff
      .icon-page-enter
        color #fff
    .btns
      width 120px
      height 42px
      position absolute
      left 20px
      top 63px
      background-color rgba(248, 148, 7, 0.5)
      border-radius 4px
      text-align center
      line-height 42px
      color rgba(255, 255, 255, 0.5)
      font-size 18px
      cursor pointer
    .btn-yes
      background-color #F89407
      color #fff
      &:hover
        background-color #DB8307
        color #fff
  .tab-center
    flex 1
    background-color #272930
    .project-list
      display flex
      height 139px
      align-items center
      justify-content space-around
      .item
        .project-info
          display flex
          padding 12px 16px
          border-radius 2px
          cursor pointer
          &:hover
            background-color #373A43
            .isInput
              border 1px solid #F89407
              input
                color #fff
            .iconDel
              box-shadow 2px 4px 6px rgba(0, 0, 0, 0.2)
              background url('~@/assets/images/close-black.png') no-repeat
              i
                content ''
          img
            width 109px
            height 82px
            border-radius 2px
            object-fit cover
          .info-detail
            position relative
            padding-left 16px
            .name
              font-size 16px
              font-weight 400
              color #FFF
            .address
              margin-top 12px
              span
                font-size 14px
                font-weight 400
                color #808191
            .status
              margin-top 2px
              span
                font-size 14px
                font-weight 400
                color #808191
            .status2
              margin-top 14px
              span
                font-size 14px
                font-weight 400
                color #B2B3BD
                i
                  color #FFCE73
            .label
              margin-top 12px
              span
                background-color #3D3F45
                color #FFFFFF
                font-size 12px
                display inline-block
                padding 3px 8px
                border-radius 13px
        .blank-img
          width 109px
          height 82px
          background-color #424752
          margin-right 16px
          border-radius 2px
          border 1px solid #424752
        .search-input
          .input-item
            display flex
            width 210px
            border-radius 2px
            background-color #2C2E38
            height 28px
            .el-icon-search
              position absolute
              left 10px
              top 7px
            input
              width 100%
              background none
              border none
              padding-left 35px
              color #fff
              height 28px
              line-height 28px
          .iconDel
            margin-left 20px
            width 22px
            height 22px
            cursor pointer
            line-height 22px
            text-align center
            i
              color #fff
              font-size 16px
              display inline-block
              transform rotate(45deg)
            &:hover
              box-shadow 2px 4px 6px rgba(0, 0, 0, 0.2)
              background url('~@/assets/images/close-black.png') no-repeat
              i
                content ''
        .flexCenter
          display flex
          justify-content space-between
          align-items center
.w-input-item
  height 28px
  line-height 28px
  border-radius 2px
  padding-left 10px
  background-color #000
  input
    width 175px
    background none
    border none !important
    padding-left 5px
    font-size 16px
    color #808191
  /deep/ .el-icon-search:before
    color #fff !important
  /deep/ .el-input__inner
    width 180px
    height 28px !important
    line-height 28px
    color #808191 !important
    font-size 16px
    padding 0 5px
    &::placeholder
      width 180px
      color #808191 !important
      font-size 16px
  /deep/ .el-autocomplete-suggestion__wrap
    padding 0
.comparison-tab-wrap-show
  position fixed
  right 0
  bottom 0
  width 52
  height 139px
  box-shadow 0px -4px 20px rgba(0, 0, 0, 0.8)
  background-color #272930
  z-index 111
  display flex
  border-radius 2px 0 0 2px
  .tab-left
    width 52px
    background-color #373A43
    writing-mode vertical-rl
    -webkit-writing-mode vertical-rl
    text-align center
    line-height 52px
    color #fff
    font-weight 400
    font-size 20px
    letter-spacing 10px
    text-indent 20px
    border-radius 2px 0 0 2px
    cursor pointer
    position relative
    .icon-page-return:before
      width 12px
      height 22px
      position absolute
      top 32%
      left 20px
      color #fff
    &:hover
      background-color #F89407
    &:focus
      background-color #DB8307
</style>
